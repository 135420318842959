.case_studies_details_banner{
    padding: 4em 0em;

    .content_wrapper{
        display: flex;
        height: 100%;
         .banner_content{
            padding: 3rem 0rem;
         }
    }

}