.SameVision {
    padding: 4em 0em;
    background-image: linear-gradient(-90deg, #dedfda, #e7e9e9);
    position: relative;

    .circles:nth-child(1) {
        position: absolute;
        top: 10%;
        left: 35%;
        width: 10px;
        height: 10px;
        background-color: #fe646c;
        border-radius: 50%;
    }

    .circles:nth-child(2) {
        position: absolute;
        top: 30%;
        left: 10%;
        width: 20px;
        height: 20px;
        background-color: #87d9f7;
        border-radius: 50%;
    }

    .circles:nth-child(3) {
        position: absolute;
        bottom: 10%;
        left: 60%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #f58634;
    }
    .imageWrapper{
        img{
            width: 80%;
            padding: 2em 0em;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        height: 100%;

        .contentDiv {
            h2 {
                font-family: monaSansRegular;
            font-weight: 600;
            color: #393939;
            }
            p{
                font-family: monaSansRegular;
                font-weight: 200;
                font-size: 15px;
                padding-top: 15px;
            }
        }
    }
}


@media screen and (max-width:767px) {
    .SameVision {
        padding: 4em 0em;

        .imageWrapper{
            width: 50%;
            margin: auto;
        }
    }

    .SameVision .wrapper .contentDiv{
        text-align: center;
    }

    .SameVision .imageWrapper img{
        width: 100%;
    }

}