header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .img_wrapper {
        padding: 1em;

        img {
            max-width: 200px;
        }
    }

    .contact_wrapper {
        padding: 1em;

        p {
            font-size: 1.1em;
        }
    }
}

footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    background-color: #1d1d1d;
    width: 100%;

    .copyright {
        color: #fff;
    }

    .social_links {
        svg {
            margin: 0 1em;
            width: 20px;
            height: 20px;
            color: #fff;
        }
    }
}

.agriculture_prediction_page {
    padding: 4em 0;
    min-height: 100vh;

    .form-control:focus, .form-select:focus {
        border-color: #8BC34A;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(76 175 80 / 29%);
    }
}