.connect_section {
    text-align: center;
    padding: 5em 0em;
    p {
        width: 60%;
        margin: auto;
    }

    button {
        margin-top: 20px;
        background: transparent;
        padding: 10px 45px;
        border: 1px solid #36bbc2;
        position: relative;
        overflow: hidden;
        transition: all 0.2s;
        z-index: 0;
        border-radius: 25px;
        color: #000;
        font-weight: 600;

        &:hover{
            color: #fff;
        }


        &::before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: 10px;
            height: 10px;
            background-image: linear-gradient(45deg, #36bbc2, #36bbc2, #36bbc2);
            border-radius: 50%;
            border: 1px solid  #36bbc2;
            transition: all 0.2s;
            z-index: -1;
        }

        &:hover::before {
            transform: scale(60);
        }
    }
}
@media screen and (max-width: 767px) {
    .connect_section p{
        width: 100%;
        padding: 0px 15px;
    }
}
@media screen and (max-width: 575px) {
    .connect_section{
        padding: 1em 0em;
    }

}