.section_two {
    padding: 4em 0;
    background-image: url('../../../media/new-landing/section2-bg.jpg');

    .video_sec {
        video {
            width: 100%;
            height: 100%;
            mix-blend-mode: screen;
        }
    }

    .content {
        display: flex;
        align-items: center;

        h2 {
            color: #FFF;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1px;

            @media screen and (min-width:280px) and (max-width:991px) {
                font-size: 35px;
            }
        }

        p {
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.7px;
            margin-top: 2em;
        }
        
        .btn_group {
            display: flex;
            margin-top: 2em;
            flex-wrap: wrap;
            button:nth-child(1) {
                font-weight: 600;
                font-size: 1.1em;
                border-radius: 46px;
                background: #FFF;
                padding: .2em 1em;
                border: none;
                display: flex;
                align-items: center;
                margin-right: 1em;
                @media screen and (min-width:280px) and (max-width:991px) {
                    height: 50px;
                }
                svg {
                    margin-right: 1em;
                }
            }

            a {
                font-weight: 600;
                border-radius: 46px;
                background: rgba(255, 255, 255, 0.08);
                color: #FFF;
                padding: .2em 1em .2em 0em;
                border: none;
                display: flex;
                align-items: center;
                margin-right: 1em;
                font-size: 1.1em;

                &>div {
                    background-color: #FFF;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 1em;
                }

                svg {}
            }

            button{
                @media screen and (min-width:280px) and (max-width:991px) {
                    margin: .5em 0;
                }
            }
        }
    }
}