.productDetails-wrap {
    padding: 50px 0px 0px;

    .productDetails-title {
        text-align: center;
        max-width: 1000px;
        margin: 0px auto;

        p {
            padding: 20px 0px;
        }
    }

    .productDetails-tabs-wrap {
        padding: 50px 0px 0px;
        background-color: #fff;

        .productDetails-web-detail {
            background: #f7f7f7;
            // background: linear-gradient(to bottom left, #ffffff 0%, #000000 100%);
            // margin-bottom: 20px;

            .productDetails-web-tab {
                padding: 20px 0px;

                .h3_title{
                    color: #36bbca;
                }

                a {
                    border: 1px solid #36bbca;
                    padding: 10px 45px;
                    position: relative;
                    overflow: hidden;
                    transition: all 0.2s;
                    z-index: 0;
                    border-radius: 25px;
                    color: rgb(0, 0, 0);
                    margin-top: 30px;

                    &:hover {
                        color: #fff;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -10px;
                        left: -10px;
                        width: 10px;
                        height: 10px;
                        background-image: linear-gradient(45deg, #36bbca, #36bbca, #36bbca);
                        border-radius: 50%;
                        border: 1px solid #36bbca;
                        transition: all 0.2s;
                        z-index: -1;
                    }

                    &:hover::before {
                        transform: scale(80);

                    }
                }

                .btn-primary:hover {
                    border-color: #36bbca;
                    color: #fff;
                }
            }

            .row {
                align-items: center;
            }
        }

        .nav-tabs {
            justify-content: center;
            border-bottom: 0px;

            .nav-link {
                color: #000;
                font-weight: 600;
                border: none;

                &:hover,
                &:focus {
                    border: none;
                }

            }

            .nav-link.active {
                border: none;
                border-bottom: 2px solid #36bbca;
                color: #36bbca;
            }

            .nav-item {
                padding: 0px 10px;
            }
        }
    }

    .productDetails-app-detail {
        background: #f7f7f7;
        padding: 20px 0px 0px;
        .row {
            align-items: center;
        }

        .productDetails-app-tab {
            .h3_title{
                color: #36bbca;
            }
            a {
                border: 1px solid #36bbca;
                padding: 10px 45px;
                position: relative;
                overflow: hidden;
                transition: all 0.2s;
                z-index: 0;
                border-radius: 25px;
                color: rgb(0, 0, 0);
                margin-top: 30px;

                &:hover {
                    color: #fff;
                }

                &::before {
                    content: "";
                    position: absolute;
                    bottom: -10px;
                    left: -10px;
                    width: 10px;
                    height: 10px;
                    background-image: linear-gradient(45deg, #36bbca, #36bbca, #36bbca);
                    border-radius: 50%;
                    border: 1px solid #36bbca;
                    transition: all 0.2s;
                    z-index: -1;
                }

                &:hover::before {
                    transform: scale(80);

                }
            }
        }

        .btn-primary:hover {
            border-color: #36bbca;
            color: #fff;
        }

        .productDetails-app-img {
            width: 400px;
            margin-left: auto;

            img {
                object-fit: cover;
            }
        }
    }
}
@media screen and (max-width: 575px) {
    .productDetails-wrap{
        padding: 20px 0px;
    }
    .productDetails-wrap .productDetails-tabs-wrap{
        padding: 20px 0px;
    }
    .productDetails-wrap .productDetails-tabs-wrap .productDetails-web-detail .productDetails-web-tab button{
        padding: 10px 25px;
        font-size: 14px;
    }
    .productDetails-wrap .productDetails-app-detail .productDetails-app-tab button{
        padding: 10px 25px;
        font-size: 14px;
    }
    .productDetails-wrap .productDetails-app-detail .productDetails-app-img{
        margin-top: 20px;
        margin-left: 0px;
        margin: 20px auto 0px auto;
    }
    .productDetails-wrap .productDetails-app-detail .productDetails-app-img{
        width: auto;
    }
}