.Web_Body {
    display: flex;
    background-color: #FAFAFB;
    padding: 60px 0px;
    margin: 20px 0px;
  }
  
  .Web_Image {
    width: 50%;
  }
  
  .Web_Img {
    width: 508px;
    height: 508px;
    background-image: url('../../../images/hyderabad/web_paragraph-3_zj1uhh.png');
    background-repeat: no-repeat;
    object-fit: cover;
    margin-left: auto;
    margin-right: 100px;
  }
  
  .Web_Content {
    width: 50%;
    padding: 100px 0px;
    padding-right: 10%;
  }
  
  .Web_Heading {
    color: #36BBC2;
    text-transform: uppercase;
    margin-top: 12px;
  }
  
  .Web_Paraone, .Web_Paratwo {
    color: #47576A;
    line-height: 26px;
    margin-top: 18px;
  }
  
  .Web_btn .Web_Click {
    width: 170px;
    height: 43px;
    cursor: pointer;
    font-size: 15px;
    color: #000000;
    background-color: #FAFAFB;
    border: 1px solid #36BBC2;
    border-radius: 22px;
    margin-top: 20px;
    transition: color 0.2s;
  
    &:hover {
      color: #FAFAFB;
    }
  
    span {
      position: relative;
      z-index: 1;
    }
  
    position: relative;
    overflow: hidden;
  
    &::before {
      width: 10px;
      height: 10px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 100px;
      margin-left: -50px;
      background-color: #36BBC2;
      border-radius: 50%;
      transition: width 0.2s, height 0.2s;
      transform: translate(-50%, -50%);
    }
  
    &:hover::before {
      width: 580px;
      height: 580px;
    }
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    .Web_Img {
      width: 460px;
      height: 460px;
      background-size: cover;
      margin: auto;
      margin-top: 50px;
    }
  
    .Web_Content {
      padding-right: 5%;
    }
  }
  
  @media screen and (min-width: 593px) and (max-width: 1023px) {
    .Web_Body {
      display: flex;
      flex-direction: column;
    }
  
    .Web_Image {
      width: 100%;
      margin: auto;
    }
  
    .Web_Img {
      width: 380px;
      height: 380px;
      background-size: cover;
      margin: auto;
    }
  
    .Web_Content {
      width: 100%;
      padding: 0px 8%;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  
    .Web_Heading {
      font-size: 15px;
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 592px) {
    .Web_Body {
      display: flex;
      flex-direction: column;
    }
  
    .Web_Image {
      width: 100%;
      margin: auto;
    }
  
    .Web_Img {
      width: 300px;
      height: 300px;
      background-size: cover;
      margin: auto;
    }
  
    .Web_Content {
      width: 90%;
      margin: auto;
      padding: 20px 10px;
    }
  
    .Web_Heading, .Web_Paraone, .Web_Paratwo {
      font-size: 14px;
    }
  
    .Web_btn .Web_Click {
      width: 140px;
      font-size: 14px;
    }
  }
  