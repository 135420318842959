.case_banner {
    padding: 4em 0em;
    background-color: #efefef;

    .head {
        margin-bottom: 2em;
        h2{
            font-weight: 900;
        }
    }
}