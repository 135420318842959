.address-wrap {
    padding: 50px 0px;
    // background-image: url("../../../images/background/address.png");
    // background-color: #e1e1e159;
    background-color: #fafafb;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;

    .ind-address-wrap {
        .card {
            background-color: #fff;
            margin-bottom: 0px;
            box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
        }
    }

    .row {
        justify-content: flex-start;
        align-items: center;
    }

    .address-title {
        text-align: center;

        .h2_title {
            padding-bottom: 30px;
        }
    }

    .card {
        // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
        border: none;
        margin-bottom: 20px;
        height: 200px;
        // align-items: center;
        // flex-direction: initial;
        position: relative;
        border-radius: 10px;

        // &::after {
        //     background: url("../../../images/coin/triangle.png");
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     position: absolute;
        //     width: 100%;
        //     height: 90px;
        //     content: "";
        //     animation-name: example;
        //     animation-duration: 4s;
        //     animation-iteration-count: infinite;
        // }

        .card-link {
            margin-left: 0px;
        }

        .card-body {
            .card-title{
                font-weight: 600;
                color: #000545;
            }
            .card-text {
                padding: 0px 0px 10px;
            }

            .address-num {
                padding-bottom: 10px;
                line-height: 2;
            }

            .address-num,
            .address-email {
                .card-link:nth-child(3) {
                    margin-left: 5px;
                }

                //     &::after {
                //         display: none;
                //     }
                // }

                // .card-link {
                //     position: relative;

                //     &::after {
                //         background: #000;
                //         position: absolute;
                //         height: 20px;
                //         width: 2px;
                //         right: -6px;
                //         bottom: 0px;
                //         content: "";
                //     }
                // }
            }
        }

    }
}

@keyframes example {
    0% {
        margin-top: 0px;
    }

    50% {
        margin-top: 10px;
    }

    100% {
        margin-top: 0px;
    }
}
@media screen and (max-width: 1400px) {
    .address-wrap .card{
        height: 230px;
    }
}
@media screen and (max-width: 991px) {
    .ind-address-wrap{
        margin-bottom: 20px;
    }
    .address-wrap .card{
        height: 200px;
    }
}
@media screen and (max-width: 575px) {
    .address-wrap .card{
        height: 175px;
    }
    .address-wrap{
        padding: 20px 0px;
    }
}
@media screen and (max-width: 380px) {
    .address-wrap .card{
        height: 200px;
    }
}
@media screen and (max-width: 320px) {
    .address-wrap .card{
        height: 230px;
    }
}