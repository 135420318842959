.hireAddLaverage_wrap {
    padding: 50px 0px 0px 0px;

    .hireAddLaverage_about {
        padding-top: 50px;
        .h3_title {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 37px;
            line-height: 47px;
            color: #000;
            margin-bottom: 20px;
            position: relative;
          
            &::after {
                position: absolute;
                width: 45px;
                height: 3px;
                content: "";
                background-color: #F94D1C;
                left: 0px;
                top: -15px;
            }
            span {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
            }
        }

        p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 19px;
            line-height: 37px;
        }
    }
}
@media screen and (max-width: 991px) {
    .hireAddLaverage_wrap .hireAddLaverage_about .h3_title{
        font-size: 31px;
        line-height: 35px;
    }
    .hireAddLaverage_wrap .hireAddLaverage_about p{
        font-size: 16px;
        line-height: 24px;
    }
}
@media screen and (max-width: 767px) {
    .hireAddLaverage_wrap .row {
        flex-direction: column-reverse;
    }
};