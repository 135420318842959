.gameFaq-accordion-wrap{
    padding: 80px 0px;
    .gameFaq-accordion-title{
        .h2_title{
            text-align: center;
            padding-bottom: 20px;
        }
    }
}
@media screen and (max-width: 767px) {
    .gameFaq-accordion-wrap{
        padding: 50px 0px;
    }
    .gameFaq-accordion-wrap .container {
        max-width: 100%;
    }
}