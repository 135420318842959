.aboutUs-wrap {
    background-image: url("../../../images/background/aboutUs/about_banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::after {
        position: absolute;
        height: 100%;
        width: 100%;
        // background: rgba(255, 255, 255, 0.852);
        background-image: linear-gradient(45deg, #000545c2, #000);
        content: "";
        left: 0px;
        top: 0px;
    }

    .aboutUs-title {
        z-index: 1;
        position: relative;

        .h2_title {
            max-width: 221px;
            color: #36ccb2;
            border-bottom: 3px solid #fff;
            margin: 0px auto;
        }

        p {
            // max-width: 700px;
            margin: 0px auto;
            margin-top: 20px;
            text-align: center;
            color: #fff;
        }
    }
}

@media screen and (max-width: 767px) {
    .aboutUs-title .h2_title {
        text-align: center;
        max-width: 120px;
        font-size: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .aboutUs-wrap .aboutUs-title p {
        font-size: 12px;
        margin-top: 5px;
    }
}