.TechnologyPartners-wrap {
    padding: 50px 0px;
    // background: url("../../../images/about/partner/partnerBg.jpg");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;

    .row {
        justify-content: space-around;
        align-items: center;
    }

    .TechnologyPartners-title {
        .h2_title {
            text-align: center;
            padding-bottom: 20px;

            span {
                font-family: heading;
                font-size: 3rem;
                color: #36ccb2;
                letter-spacing: 1px;
                margin-bottom: 0px;
            }
        }
    }
    .technology_logos{
        background-color:#F2FBFC;
        padding: 20px;
    }

    .TechnologyPartners-img {
        border-radius: 10px;
        padding: 10px 50px;
       

        img{
            width: 200px;
            margin: auto;
        }
    }
}
@media screen and (max-width: 575px) {
    .TechnologyPartners-wrap{
        padding: 20px 0px;
    }
    .TechnologyPartners-wrap .TechnologyPartners-title .h2_title span{
        font-size: 1em;
    }

}



