.outcome_section {
    .row{
        align-items: center;
    }
    padding: 5em 0em;
    .head_text {
        text-align: center;
        .h2_title{
            padding-bottom: 20px;
        }
    }

    .outcome_container {
        display: flex;
        align-items: center;
        height: 100%;

        .content_box {
            padding: 2em;
            border-radius: 5px;
            margin-bottom: 20px;
            // box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
            box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
        }
    }
}
@media screen and  (max-width: 575px) {
    .outcome_section{
        padding: 20px 0px;
    }
}