.w3-OwlCarousel-wrap {
    padding: 50px 0px;
    // background-image: url("../../../images/background/web3/owlbackground2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #fafafb;
    position: relative;

    // &::after {
    //     background: #ffffff85;
    //     position: absolute;
    //     left: 0px;
    //     top: 0px;
    //     content: "";
    //     height: 555px;
    //     width: 100%;
    // }

    .image_div{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 80%;
        }
    }

    .hero_slide_section_content {
        position: relative;
        z-index: 1;

        .hero_content_div{
            .hero_cont_para{
                word-break: break-word;
                p:nth-child(1){
                    padding: 15px 0px;
                }
            }
        }
    }

    .row {
        align-items: center;
    }

    .h3_title {
        color: #36bbc2;
    }

    button {
        background: transparent;
        padding: 10px 45px;
        border: 1px solid #36bbc2;
        position: relative;
        overflow: hidden;
        transition: all 0.2s;
        z-index: 0;
        border-radius: 25px;
        color: #000;
        margin-top: 30px;

        &:hover {
            color: #fff;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: 10px;
            height: 10px;
            background-image: linear-gradient(45deg, #36bbc2, #36bbc2, #36bbc2);
            border-radius: 50%;
            border: 1px solid #36bbc2;
            transition: all 0.2s;
            z-index: -1;
        }

        &:hover::before {
            transform: scale(100);
        }
    }

    .hero_slider {
        position: relative;

        .owl-nav {
            position: absolute;
            bottom: 10px;
            right: 20px;
            left: auto;

            .owl-prev,
            .owl-next {
                background-color: #fff0;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                border: 1px solid #36bbc2;
                color: #fff;
                font-size: 25px;
                line-height: 30px;
            }
        }
    }
}

@media (max-width: 767px) {
    .w3-OwlCarousel-wrap{
        padding: 20px 0px 20px;
    }
    .w3-OwlCarousel-wrap .container {
        max-width: 100%;
    }
    .w3-OwlCarousel-wrap .hero_slide_section_img{
        display: block;
    }
}

@media (max-width: 575px) {
    .hero_slide_section_content {
        margin-top: 20px;
    }
    .w3-OwlCarousel-wrap button{
        padding: 10px 25px;
        font-size: 14px;
    }
}