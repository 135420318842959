.keyFeature_section {
    margin: 5em 0em;

    .head {
        text-align: center;
        margin-bottom: 3em;

        .h3_title{
            color: #36bbc2;
        }
    }
    .key_box {
        // box-shadow: 0px 0px 5px grey;
        box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
        padding: 2em;
        min-height: 300px;
        // animation: float 4s linear infinite;
        svg {
            font-size: 2em;
            margin-bottom: 0.2em;
        }

        @keyframes float {
            0%{
                margin-top: 0px;
            }
            50%{
                margin-top: 100px;
            }
            100%{
                margin-top: 0px;
            }
        }
    }
    .key_box.odd{
        margin-top: 100px;
        // animation: floatOdd 4s linear infinite;

        @keyframes floatOdd {
            0%{
                margin-top: 100px;
            }
            50%{
                margin-top: 0px;
            }
            100%{
                margin-top: 100px;
            }
            
        }
    }
}
@media screen and (max-width: 991px){
    .keyFeature_section .key_box{
        margin-bottom: 20px;
    }
    .keyFeature_section .key_box.odd{
        margin-top: 0px;
    }
}
@media screen and (max-width: 575px){
    .keyFeature_section{
        margin: 1em 0em;
    }
    .keyFeature_section .key_box{
        min-height: 180px;
    }
}