.visionMission-wrap {
    padding: 50px 0px;
    .row{
        align-items: center;
    }
    .visionMission-list {
        .h2_title {
        //    padding-bottom: 10px;
            span{
                color: #000;
            }
        }

        p {
            padding-bottom: 20px;
        }

        ul {
            li {
                position: relative;

                &::after {
                    position: absolute;
                    content: "\2713";
                    top: 0px;
                    left: -15px;
                    color: orange;
                }
            }
        }
    }
    .mission-img{
        width: 80%;
        margin: 0px auto;
    }
}
@media screen and (max-width: 575px) {
    .visionMission-list{
        margin-top: 20px;
    }
    .visionMission-wrap{
        padding: 20px 0px;
    }
    .visionMission-wrap .visionMission-list p{
        padding: 0px;
    }
}