.get_in_touch_wrap {
    background-color: #1F2122;
    padding: 50px 0px;
    position: relative;
    background-image: url("../../../media/new-landing/grains.png");

    .get_in_touch_article {
        .get_in_touch_title {
            margin-bottom: 65px;

            h3 {
                color: #FFF;
                font-size: 60px;
                font-weight: 700;
                text-transform: lowercase;
                line-height: 65px;
                @media screen and (min-width: 280px) and (max-width: 991px){
                    font-size: 35px;
                }
            }

            svg {
                width: 200px;
                max-width: 100%;
            }

            p {
                color: #B8B8B8;
                font-size: 18px;
                font-weight: 400;
                padding: 15px 0px 0px;
            }
        }

        .get_in_touch_info_wrap {
            .get_in_touch_info {
                margin-bottom: 20px;

                h5 {
                    color: #FFF;
                    font-size: 22px;
                    font-weight: 700;
                }

                a,p {
                    color: #B8B8B8;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
    }

    .get_in_touch_form_wrap {
        form {
            padding: 30px 0 0 0;
        }

        form .row {
            display: flex;
        }

        form .row .input-data {
            width: 100%;
            height: 40px;
            position: relative;
            margin-bottom: 40px;
        }

        form .row .textarea {
            height: 70px;
        }

        .input-data input,
        .textarea textarea {
            display: block;
            width: 100%;
            height: 100%;
            border: none;
            font-size: 17px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.12);
        }

        .input-data input:focus~label,
        .textarea textarea:focus~label,
        .input-data input:valid~label,
        .textarea textarea:valid~label {
            transform: translateY(-20px);
            font-size: 14px;
            color: #fff;
        }

        .textarea textarea {
            resize: none;
            padding-top: 10px;
            background-color: transparent;
            border-bottom: 1px solid #B8B8B8;
            color: #fff;

            &:focus {
                box-shadow: none;
                outline: 0px;
            }
        }

        .input-data label {
            position: absolute;
            pointer-events: none;
            bottom: 10px;
            font-size: 14px;
            transition: all 0.3s ease;
            color: #fff;
            font-weight: 600;

            span {
                color: #F48533;
            }
        }

        .textarea label {
            width: 100%;
            bottom: 40px;
        }

        .input-data .underline {
            position: absolute;
            bottom: 0;
            height: 2px;
            width: 100%;
        }

        .input-data .underline:before {
            position: absolute;
            content: "";
            height: 2px;
            width: 100%;
            background: #F48533;
            transform: scaleX(0);
            transform-origin: center;
            transition: transform 0.3s ease;
        }

        .input-data input:focus~.underline:before,
        .input-data input:valid~.underline:before,
        .textarea textarea:focus~.underline:before,
        .textarea textarea:valid~.underline:before {
            transform: scale(1);
        }

        .submit-btn .input-data {
            overflow: hidden;
            height: 45px !important;
            width: 25% !important;
        }

        .submit-btn .input-data .inner {
            height: 100%;
            width: 300%;
            position: absolute;
            left: -100%;
            background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
            transition: all 0.4s;
        }

        .submit-btn .input-data:hover .inner {
            left: 0;
        }

        .submit-btn .input-data input {
            background: none;
            border: none;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
            cursor: pointer;
            position: relative;
            z-index: 2;
        }

        .input-data {
            input {
                background-color: transparent;
                border: none;
                color: #fff;
                border-bottom: 1px solid #B8B8B8;

                &:focus {
                    box-shadow: none;
                    outline: 0px;
                }
            }
        }
    }

    .form_submit_btn_wrap {
        .form_submit_btn {
            background-color: #fff;
            border-radius: 20px;
            padding: 8px 16px;
            border: none;

            svg {
                margin-right: 10px;
            }
        }
    }

    .mobile_code {
        position: relative;

        input {
            padding-left: 100px;
        }

        .form-select {
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: transparent;
            width: 98px;
            appearance: none;
            color: #fff;
            border: none;

            option {
                background-color: #000;
            }

            &:focus {
                box-shadow: none;
                outline: 0px;
            }
        }
    }

    .congrats_popup {
        text-align: center;
        position: absolute;
        z-index: 9999999999;
        top: 0px;
        background: rgba(120, 119, 119, 0.284);
        height: 100vh;
        width: 100%;
        margin: 0px auto;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: rgb(174 175 175);
        -webkit-box-shadow: 0 0 0px 1000px rgb(26 27 28) inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}