.development_section {
    padding: 3em 0em;
    background: #F9F9F9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.36);

    .head {
        margin-bottom: 2em;

        h2 {
            font-weight: 900;
            text-align: center;
        }

        p {
            padding: 2rem 3rem;
            text-align: center;
        }
    }

    .features {
        text-align: center;
        .row1{
            border-bottom: 1px solid #e2e2e2;
        }
        .border1{
            border-right: 1px solid #e2e2e2;
        }
        .border2{
            border-right: 1px solid #e2e2e2;
        }
        .border3{
            border-right: 1px solid #e2e2e2;
        }
        .border4{
            border-right: 1px solid #e2e2e2;
        }

        .feature_content {
            padding: 3rem;
            display: flex;
            gap: 10px;
            align-items: center;

            .image_div {
                img {
                    width: 25px;
                }
            }

            p {
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width:767px){
    .development_section{
        .features{
            .row1{
                border-bottom: none;
            }
            .border1{
                border-right: none;
            }
            .border2{
                border-right: none;
            }
            .border3{
                border-right: none;
            }
            .border4{
                border-right: none;
            } 
            .feature_content{
                border-bottom: 1px solid #e2e2e2;  
            }
        }
    }
}