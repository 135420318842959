.blog_section {
    margin: 0em 0em 5em 0em;

    .blog_tab_section {
        margin-top: -42px;

        .nav-tabs {
            border-bottom: 1px solid #36bbca;
        }

        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            background-color: #fff;
            border-color: #36bbca #36bbca #fff;
            color: #36bbca;
        }

        .nav-link {
            color: #000545;
        }

        ul {
            justify-content: center;
        }

        .list_of_blog_section {
            .head_search {
                margin: 1em 0em;

                input {
                    width: 100%;
                    border: none;
                    background-color: #f1f1f1;
                    padding: 0px 15px;
                    outline: none;
                    color: #47576a;
                    border-radius: 5px;
                    height: 3em;
                }
            }

            .list_of_blogs {
                .blog_loader {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                }
                .blog_div {
                    display: flex;
                    box-shadow: 0px 0px 2px grey;
                    margin: 1em;
                    border-radius: 5px;
                    overflow: hidden;

                    .img_blog {
                        width: 190px;
                        img {
                            height: 100px;
                            object-fit: fill;
                        }
                    }

                    .content_div {
                        padding: 10px 20px;
                        width: calc(100% - 20%);

                        p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient:vertical;
                        }
                    }
                }
            }
        }
    }

    .top_section {
        background-color: #f7f7f7;
        text-align: center;
        padding: 5em 0em;

        p {
            width: 50%;
            margin: auto;
        }
    }

    .all_blogs {
        margin-top: 1em;

        .search_blog {
            display: flex;

            input {
                width: 100%;
                border: none;
                background-color: #f1f1f1;
                padding: 0px 15px;
                outline: none;
                color: #47576a;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                height: 3em;
            }

            button {
                border: none;
                background-color: #ededed;
                padding: 0px 15px;
                margin-left: -10px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                outline: none;
                overflow: hidden;
                position: relative;
                transition: all 0.5s;
                z-index: 0;
            }
        }

        .blog_card {
            background-color: #fff;
            // padding: 15px;
            // box-shadow: 0px 0px 20px rgb(128 128 128 / 46%);
            box-shadow: 0px 0px 5px rgb(128 128 128 / 46%);
            border-radius: 10px;
            transition: all 0.2s;
            height: 420px;

            .blog_card_content {
                padding: 15px;
            }

            h3 {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            // &:hover {
            //     box-shadow: 0px 0px 5px rgb(128 128 128 / 46%);
            // }

            img {
                border-radius: 5px 5px 0px 0px;
                // margin-bottom: 1em;
                height: 210px;
                object-fit: fill;
                
            }

            p {
                color: #47576a;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                margin-bottom: 1em;
            }

            a {
                color: #36bbca;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .blog_section .blog_tab_section .list_of_blog_section .list_of_blogs .blog_div .img_blog img {
        width: 352px;
    }
}

@media screen and (max-width: 991px) {
    .blog_section .top_section p {
        width: 100%;
        padding: 15px;
    }
    .blog_section .blog_tab_section .list_of_blog_section .list_of_blogs .blog_div .img_blog img {
        height: 200px;
        width: 100%;
    }
}
@media screen and (max-width: 575px) {
    .blog_section .blog_tab_section .list_of_blog_section .list_of_blogs .blog_div {
        display: block;

    }
    .top_section {
        padding: 20px 0px 40px !important;
    }
    .blog_section {
        margin: 0em 0em 0em 0em;
    }
    .blog_section .all_blogs .blog_card {
        height: 400px;
    }
    .blog_section .blog_tab_section .list_of_blog_section .list_of_blogs .blog_div .img_blog{
        width: 100%;
    }
    .blog_section .blog_tab_section .list_of_blog_section .list_of_blogs .blog_div .content_div{
        width: 100%;
    }
    .blog_section .blog_tab_section .list_of_blog_section .list_of_blogs .blog_div .content_div .h4_title{
        font-size: 1.4em;
    }
}
