.headSection {
    .headDiv {
        margin: 2em 0em;

        h2 {

            text-align: center;
            font-family: monaSansRegular;
            font-weight: 600;
            color: #393939;
        }
    }

    .imageDiv{
        display: flex;
        justify-content: center;
        padding: 2em 0em;
        img{
            width: 50%;
        }
    }
}