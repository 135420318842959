.career-apply-wrap {
    .career-apply-details-wrap {
        padding: 50px 0px;
    }

    .career-apply-banner {
        // background: url("../../../images/career/career.png");
        height: 300px;
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .background_img{
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .h2_title{
            font-size: 70px;
            color: #fff;
            position: relative;
            z-index: 1;
        }
        &::after {
            background: linear-gradient(45deg, rgba(0, 5, 69, 0.5), #000);
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            top: 0px;
            left: 0px;
        }
    }

    .career-apply-form-wrap {
        // background: #ccc;
        padding: 20px;
        border-radius: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;;
        .h2_title{
            text-align: center;
            color: #36bbca;
        }
        .input_field {
            margin-bottom: 10px;
        }

        .form-floating>label {
            padding: 1rem 0rem;
        }

        .input_field.input_file {
            margin-top: 20px;
        }

        button {
            margin-top: 20px;
            background: transparent;
            padding: 10px 45px;
            border: 1px solid #36bbca;
            position: relative;
            overflow: hidden;
            transition: all 0.2s;
            z-index: 0;
            border-radius: 25px;
            color: #000;
            max-width: 100%;
            width: 100%;

            &:hover {
                color: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -10px;
                width: 10px;
                height: 10px;
                background-image: linear-gradient(45deg, #36bbca, #36bbca, #36bbca);
                border-radius: 50%;
                border: 1px solid #36bbca;
                transition: all 0.2s;
                z-index: -1;
            }

            &:hover::before {
                transform: scale(160);
            }
        }
    }


    .career-apply-details {
        .career-apply-head {
            .h2_title {
                color: #36bbca;
            }

            p:nth-child(2) {
                // font-weight: 600;
                font-size: 16px;
                padding-bottom: 10px;
            }

            p:nth-child(3) {
                // font-weight: 600;
                font-size: 14px;
            }
        }

        .career-apply-dis {


            .career-apply-skills,
            .career-apply-res {
                h2 {
                    font-family: heading;
                    font-weight: 500;
                    padding: 10px 0px;
                    font-size: 24px;
                    // color: #36bbca;
                    letter-spacing: 1px;
                    margin-bottom: 0px;
                }

                ul {
                    padding-left: 15px;

                    li {
                        font-size: 16px;
                        // font-weight: 600;
                        padding: 3px 0px;
                        list-style: auto;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .career-apply-details{
        margin-bottom: 20px;
    }
}