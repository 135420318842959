.boxSections {
    padding: 5em 0em;
    .head_section {
        text-align: center;
        margin-bottom: 1em;

        .h3_title{
                color: #36bbc2;
        }
    }
    .box_shell {
        // border: 1px solid grey;
        box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
        padding: 1em 2em;
        margin: 1em 0em;
        min-height: 260px;

        svg {
            font-size: 1.5em;
            margin-bottom: 0.5em;
        }
    }
}
@media screen and (max-width: 575px) {
    .boxSections{
        padding: 1em 0em;
    }
    .boxSections .box_shell{
        min-height: 180px;
    }
}