.section_four {
    padding: 4em 0;
    background-image: url('../../../../assets/media/new-landing/white-grains.png');

    .title {
        h2 {
            color: #14181E;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1.197px;
            text-transform: lowercase;
            max-width: 40%;
            line-height: 1;
            @media screen and (min-width:280px) and (max-width:991px) {
                max-width: 100%;
                font-size: 35px;
            }
            span{
                color: #F48533;
            }
        }
    }

    .phases {
        border-radius: 22px;
        background: #EBEBEB;
        padding: 2em;
        margin: 2em;
        position: relative;

        @media screen and (min-width:280px) and (max-width:991px) {
            margin: 1em ;
            padding: 1em;
        }

        span {
            position: absolute;
            top: 4%;
            right: 4%;
            width: fit-content;
            border-radius: 31px;
            background: #1A1A1A;
            color: #fff;
            padding: 0 1em;
        }

        h2 {
            color: #14181E;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1px;
            margin-bottom: 2em;

            @media screen and (min-width:280px) and (max-width:991px) {
                font-size: 18px;
            }
        }

        p {
            color: #2C2C2C;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.7px;
            @media screen and (min-width:280px) and (max-width:991px) {
                font-size: 14px;
            }
        }
    }

    .phases_wrapper:nth-child(odd) .phases {
        margin-top: -4em;

        @media screen and (min-width:280px) and (max-width:991px) {
            margin-top: unset;
        }
    }
}