.team-wrap {
    padding: 50px 0px;

    .row {
        justify-content: center;

        // .card {
        //     .card-shape {
        //         position: relative;
        //         .card_img_overlay{
        //             position: absolute;
        //             top: 0%;
        //             left: 0%;
        //             width: 100%;
        //             height: calc(100% - 9%);
        //             // background-color: rgba(0, 0, 0, 0.24);
        //         }
        //         .shape {
        //             svg {
        //                 fill: #ffffffd9;
        //                 transform: translateY(-29px) rotateX(180deg);
        //             }
        //         }
        //     }
        //     img{
        //         // filter: grayscale(1);
        //     }
        //     &:hover img{
        //         filter: grayscale(0);
        //     }
        // }
        .flip_card {
           
            width: 100%;
            height: 100%;
            perspective: 1000px;
            .card {
                position: relative;
                width: 100%;
                height: 100%;
                transition: transform 0.6s;
                transform-style: preserve-3d;
                backface-visibility: hidden;
                -moz-backface-visibility: hidden;

                .card-shape {
                    z-index: 2;
                    // position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;

                     img{
                        height: 306px;
                        width: 100%;
                        object-fit: cover;
                     }
                    .shape {
                        svg {
                            fill: #ffffffd9;
                            transform: translateY(-29px) rotateX(180deg);
                        }
                    }
                }
                .card-body{
                    transform: rotateY(180deg);
                    z-index: 1;
                    background-color: #fff;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
                    display: flex;
                    align-items: center;
                    background-image: url("../../../images/team/team_logo.png");
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            &:hover {
                .card {
                    transform: rotateY(180deg);
                }
            }
        }

    }





    .h2_title {
        text-align: center;
        padding-bottom: 20px;
        position: relative;

        // &::before {
        //     position: absolute;
        //     bottom: 20px;
        //     border: 1px solid orange;
        //     content: "";
        //     height: 3px;
        //     width: 240px;
        //     text-align: center;
        // }

        // &::after {
        //     position: absolute;
        //     bottom: 20px;
        //     background: url("../../../images/coin/infograins.png");
        //     background-repeat: no-repeat;
        //     content: "";
        //     height: 3px;
        //     width: 240px;
        //     text-align: center;
        // }
    }

    .team-details {
        position: relative;
        width: 100%;
        transition: all 0.5s ease;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;

        &:hover .overlay {
            height: 100%;
        }

        &:hover img {
            filter: blur(2px);
        }

        .team-about {
            text-align: center;
            padding: 15px;

            .h3_title {
                color: orange;
            }
        }

        .team-img {
            img {
                height: 300px;
                object-fit: fill;
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: #f8f9fac7;
            overflow: hidden;
            width: 100%;
            height: 0;
            transition: 0.4s ease;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0px 23px;
            text-align: center;

            .h3_title {
                color: orange;
            }

            p {
                color: #000;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .team-wrap .team-details .team-img img {
        height: 360px;
    }
}

@media screen and (max-width: 991px) {
    .team-wrap .team-details .team-img img {
        height: 300px;
    }
}

@media screen and (max-width: 767px) {
    .team-wrap .team-details .team-img img {
        height: 100%;
    }

    .team-wrap .team-details {
        margin-bottom: 20px;
    }
}





  

  
  