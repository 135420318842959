.ourTeam_section {
    padding: 4em 0;
    background: #1F2122;
    background-image: url('../../../media/new-landing/grains.png');
    // position: relative;
    overflow: hidden;
    cursor: none;

    .drag {
        width: 80px;
        height: 80px;
        background-color: #F48533;
        color: #1F2122;
        font-weight: 600;
        position: absolute;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        transform: translate(-50%, -50%);
        z-index: 4;
        backface-visibility: hidden;
        pointer-events: none;
        @media screen and (max-width:991px) {
            display: none;
        }
    }

    .title {
        margin-bottom: 2em;

        h2 {
            color: #FFF;
            font-size: 65px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1.197px;
            text-transform: lowercase;
            max-width: 35%;
            line-height: .9;
            
            @media screen and (min-width:280px) and (max-width:991px) {
                font-size: 35px;
                max-width: 100%;
            }

            span {
                color: #F48533;
                position: relative;

                svg {
                    position: absolute;
                    top: 0%;
                    left: -5%;
                    width: 110%;
                    height: 110%;
                }
            }
        }
    }

    .content {
        .team_slider {
            .item {
                .team_card_wrapper {
                    border-radius: 23.673px;
                    border: 0.515px solid #525252;
                    position: relative;
                    margin: 3em 0;
                    min-height: 410px;
                    display: flex;
                    flex-direction: column;
                    // align-items: center;\
                    justify-content: space-between;
                    flex-wrap: wrap;
                    align-content: space-between;

                    .logo {
                        position: absolute;
                        top: 0%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background-color: #fff;
                        width: fit-content;
                        padding: 1em;
                        border-radius: 50%;
                        width: 80px;
                        height: 80px;
                        display: flex;
                        align-items: center;

                        svg {
                            width: 88%;
                        }
                    }

                    .member_content {
                        padding: 2em 2em 0 2em;
                        margin-bottom: 2em;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 353px;

                        @media screen and (min-width:280px) and (max-width:991px) {
                         height: auto;
                        }

                        .description {
                            color: #B8B8B8;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1.4;
                            letter-spacing: 0.72px;
                            margin: 2em 0;

                            @media screen and (min-width:280px) and (max-width:991px) {
                                font-size: 14px;
                            }
                        }

                        .member_wrapper {
                            display: flex;
                            justify-content: flex-start;

                            img {
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                            }

                            .member_profile {
                                margin-left: 1em;

                                h6 {
                                    color: #FFF;
                                    font-size: 20.585px;
                                    font-style: normal;
                                    font-weight: 600;
                                    letter-spacing: 0.72px;
                                }

                                p {
                                    color: #B8B8B8;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    letter-spacing: 0.72px;
                                }
                            }
                        }
                    }

                    .card_footer {
                        padding: 1em 2em;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        border-top: 1px solid #525252;

                        &>div {
                            color: #FFF;
                            font-size: 20.585px;
                            font-style: normal;
                            font-weight: 600;
                            letter-spacing: 0.72px;
                        }

                        &>a {
                            color: #A1A1A1;
                            font-size: 20.585px;
                            font-style: normal;
                            font-weight: 600;
                            letter-spacing: 0.72px;
                        }
                    }
                }
            }
        }

        .navigation_icon {
            margin-top: 2em;
        }
    }
}