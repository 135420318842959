.successModel_wrap {
    height: 100vh;
    position: fixed;
    background-color: #0000003d;
    left: 0px;
    top: 0px;
    width: 100%;
    overscroll-behavior: contain;
}

.success_main_wrap {
    background-color: #FCFCFC;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    width: 400px;
    /* margin: 0px auto; */
    border-radius: 10px;
    padding: 20px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.success_wrap {
    margin: 0px auto;
    display: flex;
    justify-content: center;
}

.checkmark_ok {
    position: absolute;
    animation: grow 1.4s cubic-bezier(0.42, 0, 0.275, 1.155) both infinite;
}

.checkmark_ok path {
    fill: #34c240;

}

.checkmark_ok:nth-child(1) {
    width: 12px;
    height: 12px;
    left: 12px;
    top: 16px;
}

.checkmark_ok:nth-child(2) {
    width: 18px;
    height: 18px;
    left: 168px;
    top: 84px;
}

.checkmark_ok:nth-child(3) {
    width: 10px;
    height: 10px;
    left: 32px;
    top: 162px;
}

.checkmark_ok:nth-child(4) {
    width: 20px;
    height: 20px;
    left: 82px;
    top: -12px;
}

.checkmark_ok:nth-child(5) {
    width: 14px;
    height: 14px;
    left: 125px;
    top: 162px;
}

.checkmark_ok:nth-child(6) {
    width: 10px;
    height: 10px;
    left: 16px;
    top: 16px;
}

.checkmark_ok:nth-child(1) {
    animation-delay: 1.7s;
}

.checkmark_ok:nth-child(2) {
    animation-delay: 1.4s;
}

.checkmark_ok:nth-child(3) {
    animation-delay: 2.1s;
}

.checkmark_ok:nth-child(4) {
    animation-delay: 2.8s;
}

.checkmark_ok:nth-child(5) {
    animation-delay: 3.5s;
}

.checkmark_ok:nth-child(6) {
    animation-delay: 4.2s;
}

.checkmark {
    position: relative;
    padding: 30px;
    /*   animation: checkmark 5.6s cubic-bezier(0.42, 0, 0.275, 1.155) both; */
    animation: checkmark 2.6s cubic-bezier(0.42, 0, 0.275, 1.155) both;
}

.checkmark__check {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate3d(-50%, -50%, 0);
    fill: #fff;
}

.checkmark__back {
    animation: rotate 35s linear both infinite;
}

.checkmark__back path {
    fill: #34c240;
}

.checkmark_title {
    text-align: center;

    .h3_title {
        margin-bottom: 10px;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes grow {

    0%,
    100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

@keyframes checkmark {

    0%,
    100% {
        /* opacity: 0; */
        /* transform: scale(0); */
    }

    10%,
    50%,
    90% {
        opacity: 1;
        transform: scale(1);
    }
}