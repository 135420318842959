.description_section {
    .contact_us_section {
        .hire_dev_contact_us {
            border-radius: 15px;
            margin: 1em 0em;
            margin-bottom: 4em;
            padding: 1em;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            .send_form_btn {
                margin-top: 1em;
                padding: .5em 1.5em;
                background-color: #657bf9;
                color: #fff;
                display: block;
                width: 100%;

                .sl-spinner2 .sl-loader {
                    // width: 50px;
                    // height: 50px;
                }
            }

            input {
                border: none !important;
                border-bottom: 1px solid #c1c1c1 !important;
                padding: 0.3em 0;
                margin-top: .4em;

                &:focus {
                    border-color: #657bf9;
                }

                &:focus-visible{
                    outline: none;
                }
            }

            textarea{
                border-bottom: 1px solid #c1c1c1 !important;
            }
        }

        .side_pricing_div {
            border-radius: 15px;
            margin: 1em 0em;
            padding: 1em;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            h2 {
                font-weight: 900;
            }

            .pricing_content {
                margin: 1em 0em;

                .hire_div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        color: #627afe;
                        font-size: 1.5em;

                        sub {
                            color: #000;
                            font-size: 14px;
                        }
                    }

                    button {
                        background-color: #627afe;
                        padding: .5em 1.5em;
                        color: #fff;
                        border: none;
                        transition: all .2s;

                        &:hover {
                            transform: scale(.95);
                        }
                    }
                }
            }
        }
    }
    .hire_form_wrap{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 20px;
    }



    ul{
       margin-left: 20px;
         li{
            list-style: disc !important;
         }
    }
    ol{
        margin-left: 20px;
          li{
             list-style: disc !important;
          }
     }
}