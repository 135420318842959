.featured-wrap {
    padding: 50px 0px;
    background: url("../../../../images/trusted/owl.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .featured_img_div.axie {
        img {
            height: 50px;
            object-fit: unset;
        }
    }

    &::after {
        position: absolute;
        height: 100%;
        width: 100%;
        content: "";
        // background: #000;
        background-image: linear-gradient(45deg, #000545c2, #000);
        left: 0px;
        top: 0px;
        // opacity: 0.3;
    }

    .featured_div {
        background-color: rgb(255 255 255 / 50%);
        border-radius: 15px;
        position: relative;
        z-index: 1;
        backdrop-filter: blur(10px);
        padding: 1em 4em;

        .featured_img_div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 80px;
        }
    }

    .featured-title {
        text-align: center;
        padding-bottom: 20px;
        position: relative;
        z-index: 1;

        .h3_title {
            color: #36bbc2;
        }

        .h2_title {
            color: #fff;
        }
    }
}
@media screen and (max-width: 767px) {
    .featured-wrap .container {
        max-width: 100%;
    }
}
@media screen and (max-width: 575px) {
    .featured-wrap {
        padding: 20px 0px;
    }
}
