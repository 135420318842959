@import url("./careerApply.scss");

.career-wrap {
    .career-banner-wrap {
        // background: url("../../../images/career/career.png");
        height: 300px;
        background-size: cover;
        width: 100%;
        background-repeat: no-repeat;
        position: relative;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .background_img{
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .h2_title {
            color: #fff;
            position: relative;
            z-index: 1;
            font-size: 70px;
        }

        &::after {
            background: linear-gradient(45deg, rgba(0, 5, 69, 0.5), #000);
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            top: 0px;
            left: 0px;
        }
    }

    .opportunities-wrap {
        padding: 50px 0px;

        .row {
            justify-content: center;
        }

        .h2_title {
            text-align: center;
            padding-bottom: 30px;
        }

        .opportunities-card-wrap {
            box-shadow: rgb(0 5 69 / 8%) 0px 0px 16px;
            padding: 20px;
            text-align: center;
            border-radius: 10px;
            transition: all 0.5s ease;
            height: 300px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            &:hover {
                transform: translateY(-10px);
            }

            .opportunities-card-header {
                .h3_title {
                    color: #36bbca;
                }

                p {
                    font-weight: 600;
                }

                p:nth-child(2) {
                    padding: 15px 0px;
                }

            }

            .opportunities-card-body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 0px;

                p {
                    span {
                        font-weight: 600;
                    }
                }

                .opportunities-card-loc {
                    text-align: left;
                }
            }

            .opportunities-card-footer {
                border-top: 1px solid #ccc;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0px 0px;

                p {
                    span {
                        font-weight: 600;
                    }
                }

                .apply-btn {
                    background: transparent;
                    padding: 5px 15px;
                    border: 1px solid #36bbca;
                    position: relative;
                    overflow: hidden;
                    transition: all 0.2s;
                    z-index: 0;
                    border-radius: 25px;
                    color: #000;
                    font-size: 14px;

                    &:hover {
                        color: #fff;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -10px;
                        left: -10px;
                        width: 10px;
                        height: 10px;
                        background-image: linear-gradient(45deg, #36bbca, #36bbca, #36bbca);
                        border-radius: 50%;
                        border: 1px solid #36bbca;
                        transition: all 0.2s;
                        z-index: -1;
                    }

                    &:hover::before {
                        transform: scale(60);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .career-wrap .opportunities-wrap .opportunities-card-wrap .opportunities-card-footer .apply-btn {
        padding: 5px;
    }
}

@media screen and (max-width: 767px) {
    .career-wrap .opportunities-wrap .opportunities-card-wrap {
        margin-bottom: 20px;
        height: auto;
    }
}

@media screen and (max-width: 360px) {
    .career-wrap .opportunities-wrap .opportunities-card-wrap .opportunities-card-footer .apply-btn {
        font-size: 12px;
    }
}