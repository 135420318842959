.partner_section{
    margin: 3em 0em;
    .partner_div{
        box-shadow: #0005451a 0px 0px 16px 5px;
        padding: 1em;
        border-radius: 10px;
        .partner_slider{
            .partner_img_div{
                img{
                    width: 80px;
                    height: 80px;
                    // filter: grayscale(1);
                    transition: all .5s;
                    cursor: pointer;

                    &:hover{
                        // position: relative;
                        // z-index: 99999;
                        // top: 10px;
                        // width: 100px;
                        // height: 100px;
                        // filter: grayscale(1);
                        // transform: translateY(-5px)
                    }
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .partner_section {
        margin: 2em 0em;
    }
}