.resources-wrap {
    .resources-banner {
        // background: url("../../../images/resources/banner.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 50px 0px;
        position: relative;
        // height: 400px;

        &::after {
            // background: #0000004f;
            background-image: linear-gradient(45deg, #000545c2, #000);
            position: absolute;
            content: "";
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
        }

        .heroSection_video {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .row {
            align-items: center;
        }

        .h2_title,
        p {
            position: relative;
            z-index: 1;
            color: #fff;
        }

        .resourcesImg {
            position: relative;
            z-index: 1;
            margin-left: auto;
            width: 400px;

            p {
                max-width: 200px;
                position: absolute;
                bottom: 45px;
                left: 25%;
            }
        }
    }

    .resources-details {
        padding: 50px 0px;

        .row {
            align-items: center;
        }

        .product-about,
        .product-img {
            margin: 60px 0px;
        }
    }
}

@media screen and (max-width: 991px) {
    .resources-wrap .resources-banner .resourcesImg {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .resources-wrap .resources-banner .resourcesImg p {
        font-size: 12px;
        left: 20%;
    }
}

@media screen and (max-width: 575px) {
    .resourcesImg {
        margin-top: 20px;
    }

    .resources-wrap .resources-banner .resourcesImg p {
        font-size: 14px;
        left: 30%;
    }
    .resources-wrap .resources-details .product-img,
    .resources-wrap .resources-details .product-about {
        margin: 0px;
    }
}

@media screen and (max-width: 400px) {
    .resources-wrap .resources-banner .resourcesImg p {
        left: 20%;
        font-size: 12px;
    }
}
