.blog_section_new {
    background-image: linear-gradient(90deg, #EDEBEA 0%, #F9F9F7 100%);
    padding: 4em 0;
    position: relative;

    &::before {
        background-image: url('../../../../assets/media/new-landing/white-grains.png');
        content: '';
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
    }

    .container {
        z-index: 1;
        position: relative;

        .blog_head {
            text-align: left;

            .h2_title {
                font-family: 'Syne', sans-serif;
                color: #1F2122;
                font-size: 81.415px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 1.197px;
                text-transform: lowercase;
                @media screen and (min-width:280px) and (max-width:991px) {
                    font-size: 35px;
                }
            }
        }

        .blog_section {
            box-shadow: none;
            background: none;

            img {
                border-radius: 0;
            }

            .blog_content {
                .h3_title {
                    font-family: 'Syne', sans-serif;
                    color: #14181E;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    letter-spacing: 1.164px;
                }

                p {
                    color: #2C2C2C;
                }

                a {
                    color: #2C2C2C;
                    text-decoration: underline;
                }
            }
        }

        .blog_wrapper_new {

            .blog_list_new:nth-child(even) {
                .blog_section{
                    margin-top: -8em;
                    @media screen and (max-width:991px) {
                        margin-top: auto;
                    }
                }
            }
        }
    }
}