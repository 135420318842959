.hireAddForm_wrap {
    padding: 150px 0px 50px;

    .hireAddForm_title {
        text-align: center;
        margin-bottom: 50px;

        .h3_title {
            font-family: 'Poppins', sans-serif;
            color: #000;
            font-size: 37px;
            line-height: 40px;
            font-weight: 400;
        }

        span {
            font-family: 'Poppins', sans-serif;
            color: #000;
            font-weight: 600;
        }
    }

    .hireAddForm_inner_wrap {
        max-width: 800px;
        margin: 0px auto;

        .experience_select {
            position: relative;


            .select_experience_div {
                height: 60px;
                background: #FFFFFF;
                box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.17);
                border-radius: 15px;
                border: none;
                padding-left: 25px;
                padding-right: 20px;
                display: flex;
                align-items: center;

                .img_div_experience {
                    width: 40px;
                    height: 40px;

                    img {
                        width: 35px;
                    }
                }

                input {
                    border: none;
                    background: #FFFFFF;
                    font-weight: 600;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .angle_icon {
                    width: 20px;
                }
            }

            .experience_select {
                position: relative;


                .select_experience_div {
                    height: 60px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.17);
                    border-radius: 15px;
                    border: none;
                    padding-left: 25px;
                    padding-right: 20px;
                    display: flex;
                    align-items: center;

                    .img_div_experience {
                        width: 40px;
                        height: 40px;

                        img {
                            width: 35px;
                        }
                    }

                    input {
                        border: none;
                        background: #FFFFFF;
                        font-weight: 600;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .angle_icon {
                        width: 20px;
                    }
                }

                .experience_dropdown {
                    position: absolute;
                    width: 100%;
                    background: #fff;
                    box-shadow: 0px 0px 30px 1px rgb(0 0 0 / 17%);
                    border-radius: 15px;
                    z-index: 10;
                    padding: 5px 0px 10px 0px;
                    max-height: 200px;
                    overflow-y: auto;

                    div {
                        border-bottom: 1px solid #e4e2e2;
                        padding: 12px 0px 12px 30px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }

            .experience_dropdown {
                position: absolute;
                width: 100%;
                background: #fff;
                box-shadow: 0px 0px 30px 1px rgb(0 0 0 / 17%);
                border-radius: 15px;
                z-index: 10;
                padding: 5px 0px 10px 0px;
                max-height: 200px;
                overflow-y: auto;

                div {
                    border-bottom: 1px solid #e4e2e2;
                    padding: 12px 0px 12px 30px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }

        .technology_select {
            position: relative;


            .select_technology_div {
                height: 60px;
                background: #FFFFFF;
                box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.17);
                border-radius: 15px;
                border: none;
                padding-left: 25px;
                padding-right: 20px;
                display: flex;
                align-items: center;

                .img_div_technology {
                    width: 40px;
                    height: 40px;

                    img {
                        width: 35px;
                    }
                }

                input {
                    border: none;
                    background: #FFFFFF;
                    font-weight: 600;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .angle_icon {
                    width: 20px;
                }
            }

            .technology_dropdown {
                position: absolute;
                width: 100%;
                background: #fff;
                box-shadow: 0px 0px 30px 1px rgb(0 0 0 / 17%);
                border-radius: 15px;
                z-index: 10;
                padding: 5px 0px 10px 0px;
                max-height: 300px;
                overflow-y: auto;
                

                .icon_name {
                    border-bottom: 1px solid #e4e2e2;
                    padding: 12px 0px 12px 30px;
                    font-weight: 600;
                    cursor: pointer;
                    display: flex;
                    gap: 20px;
                     
                    .technology_icons{
                        img{
                            width: 25px;
                        }
                    }
                }
            }
        }


        .hireAdd_input {
            height: 60px;
            background: #FFFFFF;
            box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.17);
            border-radius: 15px;
            border: none;
            padding-left: 25px;
        }

        .hireAdd_button {
            background: #F94D1C;
            box-shadow: 0px -8px 15px rgba(255, 68, 0, 0.2);
            border-radius: 30px;
            border: #F94D1C;
            padding: 15px 40px;
            display: block;
            margin: 30px auto 0px;
            font-size: 22px;
        }

    }
}

@media screen and (max-width: 991px) {
    .hireAddForm_wrap .hireAddForm_title .h3_title {
        font-size: 31px;
        line-height: 35px;
    }
}

@media screen and (max-width: 360px) {
    .hireAddForm_wrap .hireAddForm_title {
        margin-bottom: 30px;
    }
}