.AboutPlatform-wrap {
    padding: 50px 0px;

    .row {
        align-items: center;
    }

    .h3_title {
        color: #36ccb2;
    }

    .AboutPlatform-details {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 20px;

        .AboutPlatform-details-text {
            width: calc(100% - 100px);
        }

        .AboutPlatform-details-img {
            margin-right: 15px;
            transition: all 0.5s ease;
            width: 100px;
            height: 50px;

            img {
                object-fit: fill;
            }

            &:hover {
                transform: translateY(-8px);
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .AboutPlatform-wrap .row {
        flex-direction: column-reverse;
    }

    .AboutPlatform-text,
    .AboutAnalysis-text {
        margin-top: 20px;
    }

    .AboutPlatform-wrap {
        padding: 20px 0px;
    }

    .AboutPlatform-img {
        margin-bottom: 20px;
    }
}