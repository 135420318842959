.testimonial_wrap {
    padding: 50px 0px;
    background-image: url("../../../media/new-landing/white-grains.png");

    .row {
        justify-content: space-evenly;
        align-items: center;
    }

    .testimonial_inner_wrap {
        background: #1F2122;
        padding: 20px;
        border-radius: 20px;
        position: relative;
        overflow: hidden;

        .testimonial_title_wrap {
            h3 {
                color: #FFF;
                font-size: 60px;
                font-weight: 700;
                text-transform: lowercase;
                line-height: 40px;
                @media screen and (min-width:280px) and (max-width:991px) {
                    font-size: 35px;
                }
            }

            p {
                color: #B8B8B8;
                font-size: 18px;
                font-weight: 400;
                @media screen and (min-width:280px) and (max-width:991px) {
                    font-size: 14px;
                }
            }

            svg {
                max-width: 100%;
            }
        }

        .testimonial_client {
            .item {
                text-align: center;

                .client_img {
                    margin-bottom: 10px;

                    img {
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        border: 2px solid #fff;
                        margin: 0px auto;
                        object-fit: cover;
                    }
                }

                .client_article {
                    h4 {
                        color: #FFF;
                        font-weight: 600;
                        font-size: 26px;
                        margin-bottom: 10px;
                    }

                    p {
                        color: #B8B8B8;
                        font-size: 18px;
                        font-weight: 400;
                        @media screen and (min-width:280px) and (max-width:991px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}