.productHeroWeb-wrap {
    padding: 50px 0px;
    // background: url("../../../../images/product/banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    &::after {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0px;
        left: 0px;
        // background: #000;
        background-image: linear-gradient(45deg, #000545c2, #000);
        content: "";
        // opacity: 0.6;
    }

    .banner_img{
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .row {
        justify-content: space-between;
        align-items: center;
    }

    .productHeroWeb-details {
        position: relative;
        z-index: 1;

        .h3_title {
            color: #36bbca;
        }

        .h2_title {
            color: #fff;
        }

        p {
            color: #fff;
        }

        button {
            margin-top: 20px;
            background: transparent;
            padding: 10px 45px;
            border: 1px solid #36bbca;
            position: relative;
            overflow: hidden;
            transition: all 0.2s;
            z-index: 0;
            border-radius: 25px;
            color: #fff;

            &:hover {
                color: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -10px;
                width: 10px;
                height: 10px;
                background-image: linear-gradient(45deg, #36bbca, #36bbca, #36bbca);
                border-radius: 50%;
                border: 1px solid #36bbca;
                transition: all 0.2s;
                z-index: -1;
            }

            &:hover::before {
                transform: scale(60);
            }
        }

    }

    .productHeroWeb-img {
        position: relative;
        z-index: 1;

        p {
            position: absolute;
            bottom: 45px;
            max-width: 251px;
            color: #fff;
            left: 95px;
        }
    }
}
@media screen and (max-width: 575px) {
    .productHeroWeb-wrap{
        padding: 20px 0px;
    }
    .productHeroWeb-img{
        margin-top: 20px;
    }
    .productHeroWeb-wrap .productHeroWeb-details button{
        font-size: 14px;
        padding: 10px 25px;
    }
}