.work_section {
    padding: 4em 0em;

    .head{
        text-align: center;
        margin-bottom: 2em;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        h2{
            font-weight: bold;
            margin-bottom: 1em;
            position: relative;
            z-index: 0;
            background: #fff;
            width: fit-content;

            &::before {
                content: 'We Work';
                position: absolute;
                top: -120%;
                z-index: -1;
                left: 0%;
                width: fit-content;
                height: 100%;
                font-size: 2.1em;
                font-weight: 900;
                // -webkit-text-stroke: 4px;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: lightgrey;
                color: #fff;
                width: 100%;
                font-family:Verdana, Geneva, Tahoma, sans-serif;
            }
        }
    }

    .step_div {
        .step {
            width: fit-content;
            border: 1px solid darkblue;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: darkblue;
        }

        h4 {
            margin: .5em 0em;
        }
    }
}