.sideBox_section {
    margin: 5em 0em;
    .box_content_div_EMPTY{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .box_EMPTY{
            width: 300px;
            height: 200px;
            background-color: #f3f3f3;
            margin: 1em;
            border-radius: 5px;
        }
    }
    .box_content_div {
        padding: 10px;

        img{
            width: 50px;
            margin-bottom: 1em;
        }

        svg {
            font-size: 2em;
            margin-bottom: 0.5em;
        }

        h4{
            color: #000545;
        }
    }
    .sideBox_section-title{
        text-align: center;
        padding-bottom: 30px;
        .h4_title{
            color: #36bbc2;
        }
        .h2_title{
            color: #000545;
        }
    }
    .side_image_content_div {
        // background: aliceblue;
        background-image: linear-gradient(198deg, rgba(0, 0, 0, 0.4), #000545af) ,url('../../../media/Blockchain-sec.webp');
        background-size: cover;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 0 49px 0px #00054547;

        svg {
            font-size: 2em;
            color: #fff;
        }

        .h3_title {
            font-size: 2em;
            margin: 1em 0em;
            color: #fff;
        }

        button {
            margin-top: 20px;
            background: transparent;
            padding: 10px 45px;
            border: 2px solid #fff;
            position: relative;
            overflow: hidden;
            transition: all 0.2s;
            z-index: 0;
            border-radius: 25px;
            color: #fff;
            max-width: 180px;
            backdrop-filter: blur(10px);

            &:hover{
                // background-color: #fff;
                color: #000;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -10px;
                width: 10px;
                height: 10px;
                background-image: linear-gradient(45deg, #fff, #fff, #fff);
                border-radius: 50%;
                // border: 1px solid #fff;
                transition: all 0.2s;
                z-index: -1;
            }
    
            &:hover::before {
                transform: scale(80);
            }

        }
    }
}

@media screen and (max-width:991px) {
    .side_image_content_div{
        height: 400px !important;
        margin-top: 2em;
        text-align: center;
    }
}