.process_section{
    padding: 4em 0em;

.head{
    // margin-bottom: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2{
        font-weight: bold;
        margin-bottom: 1em;
        position: relative;
        z-index: 0;
        background: #fff;
        width: fit-content;

        &::before {
            content: 'Process';
            position: absolute;
            top: -120%;
            z-index: -1;
            left: 0%;
            width: fit-content;
            height: 100%;
            font-size: 2.1em;
            font-weight: 900;
            // -webkit-text-stroke: 4px;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: lightgrey;
            color: #fff;
            font-family:Verdana, Geneva, Tahoma, sans-serif;
            width: 100%;
        }
    }
}
    .team_content {
        padding: 1.5em;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        margin: 1em 0em;
        height: 347px;
        img {
            width: 50px;
            margin-bottom: 1em;
        }
        @media (min-width:1024px) and (max-width:1400px){
            height: 474px;
        }
    }
}