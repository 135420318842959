.mobile_menu_wrap {
    background-color: rgb(251, 251, 251);
    display: none;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow: scroll;
    top: 82px;

    .sub_menu_drop {
        display: none;
        background: #e8e8e8;
        padding: 10px;
        border-radius: 10px;
        ul {
            padding: 0px;
            column-count: 3;
        }

        li {
            list-style: none;
            line-height: 35px;

            a {
                text-decoration: underline;
                color: #475769;
                font-weight: 600;
            }
        }
    }

    .inner_sub_list {
        padding: 0px;
        display: none;
        column-count: 3;
        background: #dbdbdb;
        padding: 10px !important;
        border-radius: 10px;
        li {
            padding-bottom: 15px;
            line-height: 25px;
        }
    }

    .mobile_main_list {
        padding: 10px;

        .main_menu_drop {
            cursor: pointer;
            position: relative;
            line-height: 35px;
            font-weight: 600;

            &::after {
                position: absolute;
                top: 0px;
                height: 100%;
                left: 0px;
                width: 100%;
                content: "";
            }
        }

        .sub_menu_drop {
            padding-left: 50px;

            .inner_sub_menu {
                cursor: pointer;
                position: relative;
                margin: 10px 0px;
                font-weight: 600;

                &::after {
                    position: absolute;
                    top: 0px;
                    height: 100%;
                    left: 0px;
                    width: 100%;
                    content: "";
                }
            }

        }
    }
}

@media screen and (max-width: 767px) {
    .mobile_menu_wrap .inner_sub_list {
        column-count: 2;
    }

    .mobile_menu_wrap .mobile_main_list .sub_menu_drop {
        padding-left: 10px;
    }

    .mobile_menu_wrap .sub_menu_drop ul {
        column-count: 2;
    }
}

@media screen and (max-width: 575px) {
    .mobile_menu_wrap .inner_sub_list li {
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 480px) {
    .mobile_menu_wrap .inner_sub_list {
        column-count: 1;
    }
}

@media screen and (max-width: 400px) {
    .mobile_menu_wrap .sub_menu_drop ul {
        column-count: 1;
    }
}