.engagement_banner {
    padding: 10em 0em;
    background-color: #efefef;
    background-image: url("../../../images/engagementModel/banner.png");
    background-repeat: no-repeat;
    background-size: cover;

    .head {
        margin-bottom: 2em;
        h2{
            font-weight: 500;
            color: #36bbc2;
            font-family: heading;
            font-size: 40px;
            letter-spacing: 1px;
        }
        p{
            color: #fff;
            font-size: 24px;
        }
    }
}