.hireAddEmpower_wrap {
    padding: 70px 0px;

    .hireAddEmpower_about {
        .empower_icon_img{
        margin-bottom: -18px;
    padding-right: 90px;

            img{
                width: 185px;
                display: block;
                margin-left: auto;
            }
        }
        .h3_title {
            color: #000;
            font-weight: 400;
            font-size: 37px;
            line-height: 47px;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 20px;
            position: relative;
          
            &::after {
                position: absolute;
                width: 45px;
                height: 3px;
                content: "";
                background-color: #F94D1C;
                left: 0px;
                top: -15px;
            }

            span {
                font-weight: 600;
                font-family: 'Poppins', sans-serif;

            }
        }

        p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 19px;
            line-height: 37px;
        }
    }
}

@media screen and (max-width: 991px) {
    .hireAddEmpower_wrap .row {
        align-items: center;
    }

    .hireAddEmpower_wrap .hireAddEmpower_about .h3_title {
        font-size: 31px;
        line-height: 35px;
    }

    .hireAddEmpower_wrap .hireAddEmpower_about p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 767px) {
    .hireAddEmpower_about {
        margin-bottom: 20px;
    }

    .hireAddEmpower_wrap .row {
        flex-direction: column-reverse;
    }
}