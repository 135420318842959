.w3Service-wrap {
    padding: 50px 0px 50px;
    // background-image: url("../../../images/background/web3/servicebg02.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #fafafb;
    position: relative;

    // &::after {
    //     position: absolute;
    //     background: #ffffff;
    //     height: 595px;
    //     width: 100%;
    //     content: "";
    //     left: 0px;
    //     top: 0px;
    //     opacity: 0.6;
    //     z-index: -1;
    // }
    .row {
        align-items: center;

        .w3-service-img{
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 80%;
            }
        }
    }

    .w3-service-about {

        .description{
            word-break: break-word;
        }

        .h3_title{
            color: #36bbc2;
        }

        p {
            padding: 15px 0px;
        }

        button {
            background: transparent;
            padding: 10px 45px;
            border: 1px solid #36bbc2;
            position: relative;
            overflow: hidden;
            transition: all 0.2s;
            z-index: 0;
            border-radius: 25px;
            color: #000;
            margin-top: 15px;

            &:hover {
                color: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: -10px;
                width: 10px;
                height: 10px;
                background-image: linear-gradient(45deg, #36bbc2, #36bbc2, #36bbc2);
                border-radius: 50%;
                border: 1px solid #36bbc2;
                transition: all 0.2s;
                z-index: -1;
            }

            &:hover::before {
                transform: scale(100);
            }
        }

        .btn-primary:hover {
            border-color: #36bbc2;

        }
    }
}

@media (max-width: 767px) {
    .w3Service-wrap{
        padding: 20px 0px;;
    }
    .w3Service-wrap .container {
        max-width: 100%;
    }
    .w3Service-wrap .w3-service-about button{
        font-size: 14px;
        padding: 10px 25px;
    }
}