.content_div_section {
    background-color: #00181c;
    padding: 50px 0px;

    .content_div_cont {
        display: flex;
        height: 100%;
        align-items: center;

        .content_div {
            h2 {
                color: #fff;
            }

            p {
                margin-bottom: 1em;
                color: #fff;
            }

            ul {
                li {
                    list-style: outside;
                    line-height: 2;
                    color: #fff;
                }
            }
        }
    }

}

@media screen and (max-width: 991px) {
    .content_img {
        img {

            height: 500px;
            object-fit: cover;
        }
    }
}
@media screen and (max-width: 575px) {
    .content_div_section{
        padding: 20px 0px;
    }
}