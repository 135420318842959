.gameHero-wrap {
    padding: 50px 0px;
    background: url("../../../../images/background/games/gameHero.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    &::after {
        position: absolute;
        height: 100%;
        width: 100%;
        content: "";
        left: 0px;
        top: 0px;
        // background: #000;
        background-image: linear-gradient(45deg, #000545c2, #000);
        // opacity: 0.5;
    }

    .row {
        align-items: center;
    }

    .gameHero-about-wrap {
        position: relative;
        z-index: 1;

        .h2_title {
            color: #36bbc2;
        }

        .h3_title {
            color: #fff;
            font-size: 3rem;
        }

        p {
            color: #fff;
            // font-weight: 600;
        }
    }

    // .banner-from-wrap {
    //     background-color: #fff;
    //     position: relative;
    //     z-index: 1;
    //     padding: 20px;
    //     border-radius: 10px;

    //     .h3_title {
    //         text-align: center;
    //         padding-bottom: 20px;
    //     }

    //     .input_field {
    //         margin-bottom: 10px;
    //     }

    //     button {
    //         background: transparent;
    //         padding: 10px 45px;
    //         border: 1px solid #36bbc2;
    //         position: relative;
    //         overflow: hidden;
    //         transition: all 0.2s;
    //         z-index: 0;
    //         border-radius: 25px;
    //         color: #fff;
    //         background-color: #36bbc2;
    //         width: 100%;
    //         margin-top: 20px;

    //         &::before {
    //             content: "";
    //             position: absolute;
    //             bottom: -10px;
    //             left: -10px;
    //             width: 10px;
    //             height: 10px;
    //             background-image: linear-gradient(45deg, #36bbc2, #36bbc2, #36bbc2);
    //             border-radius: 50%;
    //             border: 1px solid #36bbc2;
    //             transition: all 0.2s;
    //             z-index: -1;
    //         }

    //         &:hover::before {
    //             transform: scale(100);
    //         }
    //     }

    //     .btn-primary:hover {
    //         border-color: #36bbc2;
    //         color: #fff;
    //     }
    // }
    // .mobile_div {
    //     display: flex;

    //     .form-select {
    //         background-color: transparent;
    //         height: 40px;
    //         width: 140px;
    //         border: none;

    //         &:focus {
    //             box-shadow: none;
    //         }
    //     }
    // }
}

@media screen and (max-width: 767px) {
    .gameHero-wrap .container {
        max-width: 100%;
    }
}

@media screen and (max-width: 575px) {
    .gameHero-from-wrap {
        margin-top: 20px;
    }
    .gameHero-wrap .gameHero-about-wrap .h3_title{
        font-size: 2em;
    }
    .gameHero-wrap{
        padding: 20px 0px;
    }
}